import React from "react";
import { Helmet } from "react-helmet";

import Header from "components/Header";
import UsersList from "components/UsersList";

import "./styles.scss";

const Home = () => {
  return (
    <div className="home-page">

      <Helmet>
        <title>Home - Data Intelligence</title>
        <description>Home - Data Intelligence</description>
      </Helmet>

      <Header />

      <UsersList />

    </div>
  )
}

export default Home;