import React from "react";

import "./styles.scss";

const CustomInput = ({ label, className, startIcon, endIcon, error, ...restProps }) => {
  return (
    <div className={`custom-input ${error ? "error" : ""} ${className ?? ""}`}>

      {label ? (
        <div className="label">{label}</div>
      ) : null}

      <div className={`input-box ${startIcon ? "with-start-icon" : ""} ${endIcon ? "with-end-icon" : ""}`}>
        {startIcon ? (
          <div className="start-icon">
            {startIcon}
          </div>
        ) : null}

        <input {...restProps} />

        {endIcon ? (
          <div className="end-icon">
            {endIcon}
          </div>
        ) : null}

        <div className="error-box">
          {error ?? ""}
        </div>
      </div>
    </div>
  )
}

export default CustomInput;