import React from "react";
import LogoIcon from "../../assets/images/logo-icon.svg";

const Logo = () => {
  return (
    <div className="logo-box">
      <img src={LogoIcon} alt="" />
    </div>
  )
}

export default Logo;