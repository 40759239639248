import React from "react";

import CustomButton from "../CustomButton";

import "./styles.scss";

const ConfirmPopover = ({ onSuccess, onDismiss }) => {
  return (
    <div className="confirm-popover">
      <div className="label">Are you sure?</div>
      <div className="controls">
        <CustomButton outline onClick={() => onDismiss()}>No</CustomButton>
        <CustomButton outline onClick={() => onSuccess()}>Yes</CustomButton>
      </div>
    </div>
  )
};

export default ConfirmPopover;