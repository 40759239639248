import React, { useState } from "react";
import Switch from "react-switch";

import { User } from "../../services/User";

import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import Loader from "../Loader";

import "./styles.scss";

const NewUserModal = ({ onClose, shouldUpdateUser, setShouldUpdateUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState("");

  const handleCreateUser = () => {
    setIsLoading(true);
    User
      .createNewUser({
        "login": login,
        "firstName": firstName,
        "lastName": lastName,
        "password": password,
        "isAdmin": isAdmin
      })
      .then(() => {
        onClose();
        setShouldUpdateUser(shouldUpdateUser + 1);
      })
      .catch(e => {
        setError(e?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <div className="new-user-modal">

      {isLoading ? <Loader /> : null}

      <div className="headline">New user</div>
      <div className="inputs-row">
        <CustomInput
          label="Login"
          value={login}
          onChange={e => setLogin(e?.target?.value)}
          placeholder="User login"
        />
        <CustomInput
          label="Password"
          value={password}
          onChange={e => setPassword(e?.target?.value)}
          placeholder="***********"
        />
      </div>
      <div className="inputs-row last">
        <CustomInput
          label="First name"
          placeholder="First name"
          value={firstName}
          onChange={e => setFirstName(e?.target?.value)}
        />
        <CustomInput
          label="Last name"
          placeholder="Last name"
          value={lastName}
          onChange={e => setLastName(e?.target?.value)}
        />
      </div>
      {error ? <div className="error">{error}</div> : null}
      <div className="switcher-item">
        <div className="label">Make an admin</div>
        <div className="switcher-content">
          <Switch
            checked={isAdmin}
            onChange={() => setIsAdmin(!isAdmin)}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            onColor="#4C8EFF"
            offColor="#EAEAEB"
          />
        </div>
      </div>
      <div className="controls">
        <CustomButton outline onClick={onClose}>Cancel</CustomButton>
        <CustomButton onClick={handleCreateUser}>Create</CustomButton>
      </div>
    </div>
  )
}

export default NewUserModal;