import React from "react";
import Logo from "../Logo";
import "./styles.scss";

const Loader = () => {
  return (
    <div className="loader-box">
      <Logo />
    </div>
  )
}

export default Loader;