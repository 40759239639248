import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import { User } from "../../services/User";

import Loader from "../Loader";

import "./styles.scss";
import { Popover } from "react-tiny-popover";
import ConfirmPopover from "../ConfirmPopover";
import CustomButton from "../CustomButton";

const UserSessionsModal = ({ user, isOpened, onReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sessionsList, setSessionsList] = useState([]);
  const [sessionHoverId, setSessionHoverId] = useState(null);
  const [deleteSessionId, setDeleteSessionId] = useState(null);

  useEffect(() => {
    if (isOpened) {
      updateSessionsList();
    }
  }, [isOpened]);

  const updateSessionsList = () => {
    setIsLoading(true);
    User
      .getSessionsList(user.id)
      .then(res => {
        setSessionsList(res)
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          onReady();
        }, 500)
      });
  }

  const deleteUserSession = sessionId => {
    User
      .deleteSession(user.id, sessionId)
      .catch(e => console.log(e))
      .finally(() => updateSessionsList());
  }

  return (
    <div className="user-sessions-modal">

      {isLoading ? <Loader /> : null}

      <div className="headline">Active sessions</div>

      <div className="sessions-list">
        {sessionsList.map(session => (
          <div className="session-item" key={session.id}>
            <div className="text-box">
              <div className="title">{session.id}</div>

              <Popover
                isOpen={sessionHoverId === session.id}
                positions={["bottom", "right"]}
                padding={0}
                content={<div className="session-hover-container">
                  {session.userAgent}
                </div>}
              >
                <div
                  className="subtitle"
                  onMouseOver={() => setSessionHoverId(session.id)}
                  onMouseLeave={() => setSessionHoverId(null)}
                >
                  {session.userAgent}
                </div>
              </Popover>
              <div className="text">
                {session.ip}
                <div className="divider"></div>
                {moment(session.updatedAt).format("MMM DD, YYYY, hh:mm a")}
              </div>
            </div>
            <div className="controls-box">
              <Popover
                isOpen={deleteSessionId === session.id}
                positions={["top", "left"]}
                padding={4}
                content={
                  <ConfirmPopover
                    onDismiss={() => setDeleteSessionId(null)}
                    onSuccess={() => {
                      setDeleteSessionId(null);
                      deleteUserSession(session.id);
                    }}
                  />
                }
              >
                <div>
                  <CustomButton
                    outline
                    className="remove-btn"
                    onClick={() => setDeleteSessionId(session.id)}
                  >
                    End session
                  </CustomButton>
                </div>
              </Popover>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserSessionsModal;