import React, { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";

import { Server } from "../../services/Server";

import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import ChangeGeneralModal from "../ChangeGeneralModal";
import ChangeAccessModal from "../ChangeAccessModal";
import NewUserModal from "../NewUserModal";
import Loader from "../Loader";

import SearchIcon from "../../assets/images/search-icon.svg";
import ArrowDownIcon from "../../assets/images/arrow-down-icon.svg";

import "./styles.scss";
import UserSessionsModal from "../UserSessionsModal";

const TABLE_COLUMNS = [
  {
    label: "ID",
    getValue: data => data?.user?.id,
    width: "35%",
    minWidth: "200px"
  },
  {
    label: "Name",
    getValue: data => `${data?.user?.firstName} ${data?.user?.lastName}`,
    width: "25%",
    minWidth: "140px"
  },
  {
    label: "Login",
    getValue: data => data?.user?.login,
    width: "25%",
    minWidth: "100px"
  },
  {
    label: "Status",
    getValue: data => (
      <div className={`status-box ${data?.limits?.blocked ? "blocked" : ""}`}>
        {data?.limits?.blocked ? "Blocked" : "Active"}
      </div>
    ),
    width: "10%",
    minWidth: "100px"
  },
];

const UsersList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(999);
  const [usersData, setUsersData] = useState({ data: [], totalItems: 0 });
  const [activeUser, setActiveUser] = useState(null);
  const [shouldUpdateUser, setShouldUpdateUser] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  let loaded = false;

  useEffect(() => {
    if (!loaded) {
      loaded = true;
      setIsLoading(true);
      Server
        .getUsers({ limit, page })
        .then(res => setUsersData(res))
        .catch(e => console.log(e))
        .finally(() => {
          setIsLoading(false);
          loaded = false;
        });
    }
  }, [shouldUpdateUser]);

  const toggleUser = id => {
    if (activeUser !== id) {
      setActiveUser(id);
    } else {
      setActiveUser(null);
    }
  };

  const updateItemsHeight = (isModalUpdate = false) => {
    const allPopups = document.querySelectorAll(".row-popup");
    allPopups.forEach(el => {
      const popupContent = el.querySelector(".popup-content");
      if (el.id === `popup-${activeUser}`) {
        if (!popupContent.classList.contains("active") || isModalUpdate) {
          popupContent.classList.add("active");
          el.style.height = `${popupContent.offsetHeight}px`;
        } else if (!isModalUpdate) {
          popupContent.classList.remove("active");
          el.style.height = 0;
        }
      } else {
        popupContent.classList.remove("active");
        el.style.height = 0;
      }
    });
  };

  useEffect(() => {
    updateItemsHeight();
  }, [activeUser]);

  const valueIncludes = (value, substring) => value.toLowerCase().includes(substring?.toLowerCase());

  const getFilteredData = () => {
    if (!!searchValue.trim()) {
      return usersData.data.filter(it => (
        valueIncludes(it.user.login, searchValue) ||
        valueIncludes(it.user.firstName, searchValue) ||
        valueIncludes(it.user.lastName, searchValue) ||
        valueIncludes(it.user.id, searchValue)
      ));
    }
    return usersData.data;
  };

  return (
    <div className="users-list">

      {isLoading ? <Loader /> : null}


      <div className="list-header">
        <div className="left-box">
          <div className="list-title">Users list</div>
          <div className="counter">{usersData.totalItems} Users</div>
        </div>
        <div className="center-box">
          <CustomInput
            startIcon={<img src={SearchIcon} alt="" />}
            placeholder="Type name, username or ID"
            value={searchValue}
            onChange={e => setSearchValue(e?.target?.value)}
          />
        </div>

        <div className="right-box">
          <Popover
            isOpen={showNewUserModal}
            onClickOutside={() => setShowNewUserModal(false)}
            positions={["bottom", "left"]}
            padding={8}
            content={
              <NewUserModal
                onClose={() => setShowNewUserModal(false)}
                shouldUpdateUser={shouldUpdateUser}
                setShouldUpdateUser={setShouldUpdateUser}
              />
            }
          >
            <div>
              <CustomButton onClick={() => setShowNewUserModal(true)}>Add user</CustomButton>
            </div>
          </Popover>
        </div>
      </div>

      <div className="table">
        <div className="table-header">
          {TABLE_COLUMNS.map(column => (
            <div key={`column-${column.label}`} className="table-cell" style={{ width: column.width, minWidth: column.minWidth }}>
              <div className="cell-content">
                {column.label}
              </div>
            </div>
          ))}
          <div className="table-cell" style={{ width: "5%", minWidth: "42px" }}></div>
        </div>
        {getFilteredData().map(listItem => {
          const user = listItem.user;
          return (
            <div className="table-row" key={`row-${user.id}`}>
              <div className="row-content" onClick={() => toggleUser(user.id)}>
                {TABLE_COLUMNS.map((column, id) => (
                  <div key={`column-${id}-${user.id}`} className="table-cell" style={{ width: column.width, minWidth: column.minWidth }}>
                    <div className="cell-content">
                      {column.getValue(listItem)}
                    </div>
                  </div>
                ))}

                <div className="table-cell" style={{ width: "5%", minWidth: "42px" }}>
                  <div className={`arrow-btn ${activeUser === user.id ? "active" : ""}`}>
                    <img src={ArrowDownIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className="row-popup" id={`popup-${user.id}`}>
                <div className="popup-content">
                  <ChangeGeneralModal
                    user={user}
                    isOpened={user.id === activeUser}
                    shouldUpdateUser={shouldUpdateUser}
                    setShouldUpdateUser={setShouldUpdateUser}
                  />
                  <ChangeAccessModal
                    user={user}
                    isOpened={user.id === activeUser}
                    shouldUpdateUser={shouldUpdateUser}
                    setShouldUpdateUser={setShouldUpdateUser}
                    onReady={() => updateItemsHeight(true)}
                  />
                  <UserSessionsModal
                    user={user}
                    isOpened={user.id === activeUser}
                    onReady={() => updateItemsHeight(true)}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>

    </div>
  )
}

export default UsersList;