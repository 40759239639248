import { ApiService } from "./ApiService";

export class Server {
  static async getUsers({ limit = 10, page = 1 }) {
    return ApiService._requestAsync({
      url: `/admin/users?limit=${limit}&page=${page}`,
      method: "GET"
    });
  }
  static async getServerConfig() {
    return ApiService._requestAsync({
      url: `/server-config/limits`,
      method: "GET"
    });
  }
  static async postServerConfig(data) {
    return ApiService._requestAsync({
      url: `/server-config/limits`,
      method: "POST",
      data
    });
  }
}
