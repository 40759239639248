import React, { useState } from "react";
import { User } from "../../services/User";
import { Popover } from "react-tiny-popover";

import Loader from "../Loader";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import ConfirmPopover from "../ConfirmPopover";

import "./styles.scss";

const ChangeGeneralModal = ({ user, shouldUpdateUser, setShouldUpdateUser }) => {
  const [showGeneralConfirmPopover, setShowGeneralConfirmPopover] = useState(false);
  const [showUserRemoveConfirmPopover, setShowUserRemoveConfirmPopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(user?.login);
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState(user.lastName);
  const [firstName, setFirstName] = useState(user.firstName);

  const isFormDifferent = () => {
    return (
      login !== user.login ||
      password !== "" ||
      lastName !== user.lastName ||
      firstName !== user.firstName
    )
  }

  const updateUser = () => {
    setIsLoading(true);
    const data = {
      "login": login,
      "firstName": firstName,
      "lastName": lastName,
      "isBlocked": user.isBlocked,
      "isAdmin": user.isAdmin,
    };
    if (password.trim()) {
      data.password = password;
    }
    User
      .putUserData(user.id, data)
      .finally(() => {
        setIsLoading(false);
        setShouldUpdateUser(shouldUpdateUser + 1);
      })
  }

  const removeUser = () => {
    setIsLoading(true);
    User
      .deleteUser(user.id)
      .finally(() => {
        setIsLoading(false);
        setShouldUpdateUser(shouldUpdateUser + 1);
      })
  }

  return (
    <div className="change-general-modal">

      {isLoading ? <Loader /> : null}

      <div className="headline">General</div>

      <div className="inputs-row">
        <CustomInput
          label="Login"
          value={login}
          onChange={e => setLogin(e?.target?.value)}
        />
        <CustomInput
          type="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e?.target?.value)}
          placeholder="***********"
        />
      </div>
      <div className="inputs-row last">
        <CustomInput
          label="First name"
          placeholder="First name"
          value={firstName}
          onChange={e => setFirstName(e?.target?.value)}
        />
        <CustomInput
          label="Last name"
          placeholder="Last name"
          value={lastName}
          onChange={e => setLastName(e?.target?.value)}
        />
      </div>
      <div className="notification-text">Updating the password will also update its associated API key.</div>
      <div className="controls">
        <Popover
          isOpen={showUserRemoveConfirmPopover}
          onClickOutside={() => setShowUserRemoveConfirmPopover(false)}
          positions={["top", "right"]}
          padding={8}
          content={
            <ConfirmPopover
              onDismiss={() => setShowUserRemoveConfirmPopover(false)}
              onSuccess={() => {
                setShowUserRemoveConfirmPopover(false);
                removeUser();
              }}
            />
          }
        >
          <div>
            <CustomButton outline className="remove-btn" onClick={() => setShowUserRemoveConfirmPopover(true)}>Delete user</CustomButton>
          </div>
        </Popover>
        <Popover
          isOpen={showGeneralConfirmPopover}
          onClickOutside={() => setShowGeneralConfirmPopover(false)}
          positions={["top", "right"]}
          padding={8}
          content={
            <ConfirmPopover
              onDismiss={() => setShowGeneralConfirmPopover(false)}
              onSuccess={() => {
                setShowGeneralConfirmPopover(false);
                updateUser();
              }}
            />
          }
        >
          <div>
            <CustomButton disabled={!isFormDifferent()} onClick={() => setShowGeneralConfirmPopover(true)}>Update</CustomButton>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default ChangeGeneralModal;