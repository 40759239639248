import { ApiService } from "./ApiService";

export class User {
  static async login(data) {
    return ApiService._requestAsync({
      url: "/auth/login",
      method: "POST",
      data
    });
  }

  static async createNewUser(data) {
    return ApiService._requestAsync({
      url: `/admin/users`,
      method: "POST",
      data
    });
  }
  static async putUserData(userId, data) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}`,
      method: "PUT",
      data
    });
  }
  static async getUsageLimit(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/usage-limit`,
      method: "GET"
    });
  }
  static async putUsageLimit(userId, data) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/usage-limit`,
      method: "PUT",
      data
    });
  }
  static async getSessionsList(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/sessions`,
      method: "GET",
    });
  }
  static async deleteSession(userId, sessionId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/sessions/${sessionId}`,
      method: "DELETE",
    });
  }
  static async deleteUser(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}`,
      method: "DELETE",
    });
  }
}
