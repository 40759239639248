import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

import * as tokenHelper from "./helpers/tokenHelper";

const PrivateRoute = ({ component: Component }) => {
  if (tokenHelper.hasToken()) {
    return (
      <Component />
    )
  }
  return <Navigate to="/login" replace />
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.instanceOf(Object)
};

PrivateRoute.defaultProps = {
  component: {},
  location: {}
};

export default PrivateRoute;
