import React, { useEffect, useState } from "react";
import Switch from "react-switch";

import { User } from "../../services/User";

import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import Loader from "../Loader";

import "./styles.scss";

const ChangeAccessModal = ({ user, isOpened, shouldUpdateUser, setShouldUpdateUser, onReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [usageLimit, setUsageLimit] = useState({});
  const [isSearchingBlocked, setIsSearchingBlocked] = useState(false);
  const [isAccountBlocked, setIsAccountBlocked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(user.isAdmin);
  const [limit, setLimit] = useState(0);
  const [ttlUnit, setTtlUnit] = useState(0);
  const [ttlInterval, setTtlInterval] = useState(0);
  const [storageBytesLimit, setStorageBytesLimit] = useState(0);
  const [periodRequests, setPeriodRequests] = useState(0);
  const [limits, setLimits] = useState({
    used: 0,
    max: 0
  })

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  useEffect(() => {
    setIsAccountBlocked(!!user?.isBlocked);
  }, [user.isBlocked]);

  const isFormDifferent = () => {
    return (
      +limit !== usageLimit.periodRows ||
      +ttlUnit !== usageLimit.ttlRequests ||
      +ttlInterval !== usageLimit.ttl ||
      isSearchingBlocked !== usageLimit?.blocked ||
      isAdmin !== user.isAdmin ||
      +storageBytesLimit !== usageLimit.storageBytesLimit ||
      +periodRequests !== usageLimit.storageBytesLimit
    )
  }

  useEffect(() => {
    if (isOpened) {
      setIsLoading(true);
      User
        .getUsageLimit(user.id)
        .then(res => {
          setUsageLimit(res);
          setLimit(res.periodRows);
          setTtlUnit(res.ttlRequests);
          setPeriodRequests(res.periodRequests);
          setTtlInterval(res.ttl);
          setLimits({
            used: res.usage.rows,
            max: res.periodRows
          });
          setStorageBytesLimit(res.storageBytesLimit);
          setIsSearchingBlocked(res.blocked);
        })
        .finally(() => {
          setIsLoading(false);
          onReady(true);
        });
    }
  }, [isOpened, shouldUpdateUser]);

  const updateUser = () => {
    setIsLoading(true);
    User
      .putUsageLimit(user.id, {
        ttl: Number(ttlInterval),
        ttlRequests: Number(ttlUnit),
        blocked: isSearchingBlocked,
        periodRequests: Number(periodRequests),
        periodRows: Number(limit),
        storageBytesLimit: Number(storageBytesLimit)
      })
      .finally(() => {
        User
          .putUserData(user.id, {
            "login": user.login,
            "firstName": user.firstName,
            "lastName": user.lastName,
            "isBlocked": isAccountBlocked,
            "isAdmin": isAdmin
          })
          .finally(() => {
            setIsLoading(false);
            setShouldUpdateUser(shouldUpdateUser + 1)
          })
      })
  }

  return (
    <div className="change-access-modal">

      {isLoading ? <Loader /> : null}

      <div className="headline">
        <div className="title">
          Access
        </div>

        <div className="limits-box">
          <div className="progress-bar">
            <div className="progress-line" style={{ width: `${limits.used * 100 / limits.max}%` }}></div>
          </div>
          <div className="text">
            <span className="used">{formatter.format(limits.used)}</span>
            <span className="max">/ {formatter.format(limits.max)}</span>
          </div>
        </div>
      </div>
      <div className="inputs-row">
        <CustomInput
          type="number"
          label="Credits for Period"
          value={limit}
          onChange={e => setLimit(e?.target?.value)}
        />
        <CustomInput
          type="number"
          label="Period (days)"
          value={periodRequests}
          onChange={e => setPeriodRequests(e?.target?.value)}
        />
      </div>
      <div className="inputs-row">
        <CustomInput
          type="number"
          className="half"
          label="Records"
          value={ttlUnit}
          onChange={e => setTtlUnit(e?.target?.value)}
        />
        <CustomInput
          type="number"
          className="half"
          label="RL (Seconds)"
          value={ttlInterval}
          onChange={e => setTtlInterval(e?.target?.value)}
        />
      </div>
      <CustomInput
        type="number"
        label="Storage Bytes Limit"
        value={storageBytesLimit}
        onChange={e => setStorageBytesLimit(e?.target?.value)}
      />
      <div className="switchers-row">
        <div className="switcher-item">
          <div className="label">Search state</div>
          <div className="switcher-content">
            <Switch
              checked={!isSearchingBlocked}
              onChange={() => setIsSearchingBlocked(!isSearchingBlocked)}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={36}
              onColor="#4C8EFF"
              offColor="#EAEAEB"
            />
            <div className={`state-box ${!isSearchingBlocked ? "active" : ""}`}>
              {!isSearchingBlocked ? "Active" : "Blocked"}
            </div>
          </div>
        </div>
        <div className="switcher-item">
          <div className="label">Account state</div>
          <div className="switcher-content">
            <Switch
              checked={!isAccountBlocked}
              onChange={() => setIsAccountBlocked(!isAccountBlocked)}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={36}
              onColor="#4C8EFF"
              offColor="#EAEAEB"
            />
            <div className={`state-box ${!isAccountBlocked ? "active" : ""}`}>
              {!isAccountBlocked ? "Active" : "Blocked"}
            </div>
          </div>
        </div>
        <div className="switcher-item">
          <div className="label">Make an admin</div>
          <div className="switcher-content">
            <Switch
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={36}
              onColor="#4C8EFF"
              offColor="#EAEAEB"
            />
          </div>
        </div>
      </div>
      <div className="controls">
        <CustomButton disabled={!isFormDifferent()} onClick={updateUser}>Update</CustomButton>
      </div>
    </div>
  )
}

export default ChangeAccessModal;