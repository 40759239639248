import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { Server } from "../../services/Server";

import Loader from "../Loader";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";

import "./styles.scss";

const GeneralLimitsModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverConfig, setServerConfig] = useState({});
  const [limit, setLimit] = useState(0);
  const [ttlUnit, setTtlUnit] = useState(0);
  const [ttlInterval, setTtlInterval] = useState(0);
  const [storageBytesLimit, setStorageBytesLimit] = useState(0);
  const [periodRequests, setPeriodRequests] = useState(0);

  const isFormDifferent = () => {
    return (
      +limit !== serverConfig.periodRows ||
      +ttlUnit !== serverConfig.ttlRequests ||
      +ttlInterval !== serverConfig.ttl ||
      +storageBytesLimit !== serverConfig.storageBytesLimit ||
      +periodRequests !== serverConfig.periodRequests
    )
  }

  useEffect(() => {
    setIsLoading(true);
    Server
      .getServerConfig()
      .then(res => {
        setServerConfig(res);
        setLimit(res.periodRows);
        setTtlUnit(res.ttlRequests);
        setTtlInterval(res.ttl);
        setStorageBytesLimit(res.storageBytesLimit);
        setPeriodRequests(res.periodRequests);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  const handleSaveChanges = () => {
    Server
      .postServerConfig({
        "blocked": serverConfig.blocked,
        "ttl": Number(ttlInterval),
        "ttlRequests": Number(ttlUnit),
        "periodRequests": Number(periodRequests),
        "periodRows": Number(limit),
        "storageBytesLimit": Number(storageBytesLimit)
      })
      .then(res => {
        toast.success("Changes were saved successfully");
      })
  };

  return (
    <div className="general-limits-modal">

      {isLoading ? <Loader /> : null}


      <div className="headline">General limit setting </div>
      <div className="inputs-row">
        <CustomInput
          type="number"
          label="Credits for Period"
          value={limit}
          onChange={e => setLimit(e?.target?.value)}
        />
        <CustomInput
          type="number"
          label="Period (days)"
          value={periodRequests}
          onChange={e => setPeriodRequests(e?.target?.value)}
        />
      </div>
      <div className="inputs-row">
        <CustomInput
          type="number"
          className="half"
          label="Records"
          value={ttlUnit}
          onChange={e => setTtlUnit(e?.target?.value)}
        />
        <CustomInput
          type="number"
          className="half"
          label="RL (Seconds)"
          value={ttlInterval}
          onChange={e => setTtlInterval(e?.target?.value)}
        />
      </div>
      <CustomInput
          type="number"
          label="Storage Bytes Limit"
          value={storageBytesLimit}
          onChange={e => setStorageBytesLimit(e?.target?.value)}
        />
      <div className="controls">
        <CustomButton outline onClick={onClose}>Cancel</CustomButton>
        <CustomButton disabled={!isFormDifferent()} onClick={handleSaveChanges}>Save</CustomButton>
      </div>
    </div>
  )
}

export default GeneralLimitsModal;