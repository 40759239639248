import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { useNavigate } from "react-router-dom";

import Logo from "../Logo";

import LimitIcon from "../../assets/images/limit-icon.svg";
import LogoutIcon from "../../assets/images/logout-icon.svg";
import GeneralLimitsModal from "../GeneralLimitsModal";

import "./styles.scss";
import CustomButton from "../CustomButton";

const Header = () => {
  const navigate = useNavigate();
  const [showLimitsModal, setShowLimitsModal] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/login");
  }

  return (
    <div className="header">
      <Logo />

      <div className="controls-box">
        <Popover
          isOpen={showLimitsModal}
          positions={["bottom", "left"]}
          onClickOutside={() => setShowLimitsModal(false)}
          padding={8}
          content={<GeneralLimitsModal onClose={() => setShowLimitsModal(false)} />}
        >
          <div>
            <CustomButton className="limit-btn" outline onClick={() => setShowLimitsModal(!showLimitsModal)}>
              <img src={LimitIcon} alt="" />
              Limit settings
            </CustomButton>
          </div>
        </Popover>

        <div className="user-btn">D</div>

        <div className="logout-btn" onClick={handleLogout}>
          <img src={LogoutIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Header;